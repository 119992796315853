import React from "react";
// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  Divider,
  Input,
} from "@mui/material";
import { ArrowUpward } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SummaryGrid from "../../../components/src/SummaryGrid";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import SearchController, { Props } from "./SearchController";

export default class Search extends SearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      //Merge Engine End DefaultContainer
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container sx={{ marginTop: "400pxw" }}>
            {/* Customizable Area Start */}
            <Container sx={{
              maxHeight: '70vh',
              overflowY: 'auto',
              padding: "0",
              margin: "0",
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 2, mb: 2, color: '#FFD700', fontSize: "28px" }}>
                <span style={{ color: "#ffffff", fontSize: "28px" }}> Summary </span>
              </Typography>
              <Card sx={{ backgroundColor: 'transparent', mb: 4, border: "2px solid #C2B30A", borderRadius: "8px" }}>
                <CardContent>

                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#fff', mb: 1, }}>
                        Orders in Progress
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#bfbfbf' }}>
                        Baker, here you can see the orders that are in progress and pending delivery. Ensure every dish is perfect before it goes out!
                      </Typography>
                    </Box>
                    <ChevronRightIcon
                      onClick={this.goToDashboard}
                      sx={{
                        color: "#C2B30A"
                      }} /></Box>
                </CardContent>
              </Card>
              <Box
                sx={{
                  justifyContent: "flex-end",
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}>
                <Box
                  sx={{
                    backgroundColor: '#3c3c3c',
                    padding: '6px 12px',
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '150px',
                  }}
                >
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      sx={{
                        color: '#A8A29E',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: 'normal',
                        marginTop: "2px",
                        '&.Mui-focused': {
                          color: '#A8A29E'
                        },
                      }}
                    >
                      Date:
                    </InputLabel>
                    <Select
                      disableUnderline
                      IconComponent={ArrowDropDownIcon}
                      sx={{
                        fontWeight: 700,
                        color: '#fff',
                        '.MuiSelect-icon': {
                          color: '#bfbf4f',
                        },
                        '.MuiSelect-select': {
                          padding: '0px',
                        },
                        backgroundColor: 'transparent',
                      }}
                      defaultValue="Last 7 days"
                    >
                      <MenuItem value="Last 7 days">Last 7 days</MenuItem>
                      <MenuItem value="Last 30 days">Last 30 days</MenuItem>
                      <MenuItem value="Last 6 months">Last 6 months</MenuItem>
                      <MenuItem value="Last 1 year">Last 1 year</MenuItem>

                    </Select>
                  </FormControl>


                </Box>
              </Box>

              {Salesdasboard(this.state.summaryData)}
              {UserDasboard(this.state.summaryData)}
            </Container>
            <Modal
              open={this.state.isVisible}
              onClose={this.hideModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Box sx={{ minHeight: 250 }}>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Typography variant="h6" gutterBottom display="inline">
                      Id:
                    </Typography>{" "}
                    {this.state.activeId}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Typography variant="h6" gutterBottom display="inline">
                      First Name:
                    </Typography>{" "}
                    {this.state.activeFirstName}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Typography variant="h6" gutterBottom display="inline">
                      Last Name:
                    </Typography>{" "}
                    {this.state.activeLastName}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Typography variant="h6" gutterBottom display="inline">
                      User Name:
                    </Typography>{" "}
                    {this.state.activeUserName}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Typography variant="h6" gutterBottom display="inline">
                      Email:
                    </Typography>{" "}
                    {this.state.activeEmail}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Typography variant="h6" gutterBottom display="inline">
                      Phone Number:
                    </Typography>
                    {this.state.activePhoneNumber}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Typography variant="h6" gutterBottom display="inline">
                      Country Code:
                    </Typography>
                    {this.state.activeCountryCode}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Typography variant="h6" gutterBottom display="inline">
                      Type:
                    </Typography>
                    {this.state.activeType}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Typography variant="h6" gutterBottom display="inline">
                      Device Id:
                    </Typography>
                    {this.state.activeDeviceId}
                  </Typography>

                  <Typography variant="subtitle1" gutterBottom component="div">
                    <Typography variant="h6" gutterBottom display="inline">
                      Created At:
                    </Typography>
                    {this.state.activeCreatedAt}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "10px 0px",
                  }}
                >
                  <Button
                    data-test-id="btnCloseModal"
                    variant="contained"
                    onClick={() => this.hideModal()}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Modal>
            {/* Customizable End Start */}
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const Salesdasboard = (summaryData: { total_sales: number; orders_completed: number; average_check: number; prep_times: number; no_of_users: number; new_users: number; return_users: number; }) => {
  return (
    <Box sx={{ backgroundColor: 'transparent', color: 'white', borderRadius: 2 }}>
      <Typography variant="h5" sx={{
        fontSize: "20px",
        fontWeight: "700",
        color: "#A8A29E"
      }} gutterBottom>
        Sales
      </Typography>
      <Divider sx={{ backgroundColor: '#A8A29E', marginBottom: "16px" }} />
      <Grid spacing={4}>
        <Grid item xs={12}>
          <SummaryGrid
            title="Total Sales"
            value={`$${summaryData.total_sales}`}
            unit="usd"
            percentage={10}
          />
        </Grid>
        <Divider
          sx={{
            margin: '16px 0',
            backgroundColor: '#A8A29E',
          }}
        />
        <Grid item xs={12}>
          <SummaryGrid
            title="Orders Completed"
            value={summaryData.orders_completed}
            unit=""
            percentage={10}
          />
        </Grid>
        <Divider
          sx={{
            margin: '16px 0',
            backgroundColor: '#A8A29E',
          }}
        />
        <Grid item xs={12}>
          <SummaryGrid
            title="Average Check"
            value={`$${Number(summaryData.average_check).toFixed(2)}`}
            unit="usd"
            percentage={10}
          />
        </Grid>
        <Divider
          sx={{
            margin: '16px 0',
            backgroundColor: '#A8A29E',
          }}
        />
        <Grid item xs={12}>
          <SummaryGrid
            title="Prep Times"
            value={`${summaryData.prep_times}`}
            unit="min"
            percentage={10}
          />
        </Grid>
        <Divider
          sx={{
            margin: "16px 0", mb: 5,
            backgroundColor: '#A8A29E'
          }} />
      </Grid>
    </Box>

  )
}

const UserDasboard = (summaryData: { total_sales: number; orders_completed: number; average_check: number; prep_times: number; no_of_users: number; new_users: number; return_users: number; }) => {
  return (
    <Box sx={{ backgroundColor: 'transparent', color: 'white', marginTop: "40px", mt: 1, borderRadius: 2, }}>
      <Typography sx={{
        fontSize: "20px",
        fontWeight: "700",
        color: "#A8A29E"
      }} gutterBottom>
        Users
      </Typography>
      <Divider sx={{ backgroundColor: '#A8A29E', marginBottom: "16px" }} />
      <Grid spacing={4}>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Box >
              <Typography variant="body1" sx={webstyle.typo}>
                Number of users
              </Typography>
            </Box>
            <Box textAlign="right">
              <Typography variant="h5" sx={webstyle.num}>{summaryData.no_of_users}</Typography>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <ArrowUpward
                  sx={{
                    fontSize: 'small',
                    color: '#10B981',
                    marginBottom: "2px"
                  }} />
                <Typography
                  ml={0.5}
                  variant="body2"
                  component="span"
                >
                  <Typography
                    component="span"
                    color="#10B981"
                    fontWeight="bold"
                  >
                    10%
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ color: '#a8a29e', ml: 0.5 }}
                  >
                    compared to previous
                  </Typography>
                </Typography>
              </Box>
            </Box>


          </Box>

        </Grid>

        <Divider sx={{

          backgroundColor: '#A8A29E',
          margin: "16px 0"
        }} />

        <Grid item xs={12}>

          <Box display="flex" justifyContent="space-between"
          >
            <Box>

              <Typography variant="body1" sx={webstyle.typo}>
                New Users
              </Typography>
            </Box>

            <Box textAlign="right">

              <Typography variant="h5"
                sx={webstyle.num}>{summaryData.new_users}
              </Typography>

              <Box display="flex" alignItems="center"
                justifyContent="flex-end">

                <ArrowUpward
                  sx={{
                    fontSize: 'small',
                    color: '#10B981',
                    marginBottom: "2px"
                  }} />
                <Typography
                  ml={0.5}
                  variant="body2"
                  component="span"
                >
                  <Typography
                    component="span"
                    color="#10B981"
                    fontWeight="bold"
                  >
                    10%
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ color: '#a8a29e', ml: 0.5 }}
                  >
                    compared to previous
                  </Typography>
                </Typography>
              </Box>

            </Box>

          </Box>
        </Grid>
        <Divider sx={{ backgroundColor: '#A8A29E', margin: "16px 0" }} />

        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="body1" sx={webstyle.typo}>
                Returning Users
              </Typography>
            </Box>
            <Box textAlign="right">
              <Typography variant="h5" sx={webstyle.num}>{summaryData.return_users}</Typography>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <ArrowUpward
                  sx={{
                    fontSize: 'small',
                    color: '#10B981',
                    marginBottom: "2px"
                  }} />
                <Typography
                  ml={0.5}
                  variant="body2"
                  component="span"
                >
                  <Typography
                    component="span"
                    color="#10B981"
                    fontWeight="bold"
                  >
                    10%
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ color: '#a8a29e', ml: 0.5 }}
                  >
                    compared to previous
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Divider
          sx={{
            margin: "16px 0",

            backgroundColor: '#A8A29E'
          }} />
      </Grid>
    </Box>
  )
}

const webstyle = {
  typo: {
    fontWeight: 700,
    fontSize: '16px',
    color: '#fff',
  },
  num: {
    fontSize: "18px",
    fontWeight: "700",
    color: '#fff',
    marginRight: "15px"
  }
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // p: 4,
};
// Customizable Area End
