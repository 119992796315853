import React from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import UserProfileController, { Props } from "./UserProfileController.web";
import * as Yup from "yup";
import { Field, Formik} from "formik";
import CustomDialog from '../../../components/src/CustomDialog';
import { Box, Button, Typography, InputLabel } from "@mui/material";

export default class Profile extends UserProfileController {
  constructor(props: Props) {
    super(props);
  }

  renderInputField = (fieldProps: {
    label: string;
    id: string;
    placeholder: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    touched?: boolean;
    testId: string
  }) => (
    <>
      <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px", width: "100%" }}>{fieldProps.label}</InputLabel>
      <OutlinedInput
        fullWidth
        id={fieldProps.id}
        style={webStyles.inputLabel}
        placeholder={fieldProps.placeholder}
        type="text"
        onChange={fieldProps.onChange}
        value={fieldProps.value}
        sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
        data-test-id={fieldProps.testId}
      />
      {fieldProps.error && fieldProps.touched && <span style={webStyles.errorStyle}>{fieldProps.error}</span>}
    </>
  );

  renderSelectField = (value: any, onChange: any) => (
    <Field name="countryCode" as="select" value={value} onChange={onChange} style={webStyles.countryCode}>
      <option value="91">+91</option>
      <option value="1">+1</option>
      <option value="44">+44</option>
    </Field>
  );

  handleFieldChange = (field: string, handleChange: any) => {
    return (e: any) => handleChange(field)(e);
  };

  render() {
    const initialValues = this.state.user || {
      countryCode: '',
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      address: "",
      state: "",
      zipcode: "",
    };

    return (
      <Box sx={{ display: "flex", marginTop: 5, flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px" }}>
        <div>
          <label htmlFor="myfileid">
            <img src={this.state.profile_pic || require("../assets/user.png")} style={webStyles.profileImg} height="80" width="80" alt="profile" />
          </label>
          <input type="file" data-test-id="upload-btn" onChange={(e) => this.updateProfilePic(e.target.files)} name="something" id="myfileid" style={{ display: "none" }} />
        </div>

        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-evenly", alignItems: "center" }}>
          <Typography variant='subtitle2' data-test-id="name" style={{ fontSize: '14px', fontWeight: 700, lineHeight: '17.78px' }}>
            {this.state.user?.firstName} {this.state.user?.lastName}
          </Typography>
          <Typography data-test-id="email" variant='body2'>{this.state.user?.email}</Typography>
        </div>

        <Formik
          data-test-id="formik"
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object().shape({
            lastName: Yup.string().required("Last name is required"),
            firstName: Yup.string().required("First name is required"),
            email: Yup.string().email().required("Email is required"),
            phone: Yup.string().required("Invalid phone number"),
            state: Yup.string().required("State is required"),
            address: Yup.string().required("Address is required"),
            zipcode: Yup.string().required("Zipcode is required"),
          })}
          onSubmit={(values) => this.saveProfile(values)}
        >
          {({ handleSubmit, handleChange, touched, values, errors }) => (
            <Box flexDirection={'column'} alignItems={'center'} display={'flex'} width={'65%'} justifyContent={'center'}>
              {['firstName', 'lastName'].map((field) => (
                this.renderInputField({
                  label: this.getLabel(field),
                  id: field,
                  placeholder: field.charAt(0).toUpperCase() + field.slice(1),
                  value: values[field],
                  onChange: this.handleFieldChange(field, handleChange),
                  error: this.getErrorValue(field, errors),
                  touched: this.getTouchedValue(field, touched),
                  testId: `${field}Input`,
                })
              ))}

              <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px", width: "100%" }}>Phone Number</InputLabel>
              <span style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '10px' }}>
                {this.renderSelectField(this.state.user.countryCode, (e: any) => {
                  const newCountryCode = e.target.value;
                  this.setState({ user: { ...this.state.user, countryCode: newCountryCode } });
                })}
                <OutlinedInput
                  style={webStyles.phoneStyle}
                  fullWidth
                  type="text"
                  id="phone"
                  value={values.phone}
                  placeholder='0000000000'
                  data-test-id='phoneInput'
                  onChange={handleChange("phone")}
                />
              </span>
              {errors.phone && touched.phone && <span style={webStyles.errorStyle}>{errors.phone}</span>}

              {['address', 'state', 'zipcode'].map((field) => (
                this.renderInputField({
                  label: this.getLabel1(field),
                  id: field,
                  placeholder: field.charAt(0).toUpperCase() + field.slice(1),
                  value: values[field],
                  onChange: this.handleFieldChange(field, handleChange),
                  error: this.getErrorValue(field, errors),
                  touched: this.getTouchedValue(field, touched),
                  testId: `${field}Input`,
                })
              ))}

              <Button
                data-test-id="submitBtn"
                sx={{
                  borderRadius: '8px',
                  padding: '15px',
                  backgroundColor: '#C2B30A',
                  color: '#ffffff',
                  width: '343px',
                  border: "1px solid #C2B30A",
                  fontWeight: 700,
                  height: '52px',
                  marginTop: "22px",
                  fontSize: '18px',
                  '&:hover': { backgroundColor: '#a59a00', border: "1px solid #a59a00" },
                  textTransform: 'none',
                }}
                variant="outlined"
                color="secondary"
                onClick={() => handleSubmit()}
              >
                Save Changes
              </Button>
            </Box>
          )}
        </Formik>

        <CustomDialog
          isOpen={this.state.open}
          testID="profileUpdateDialog"
          title={this.state.error ? "Error Updating Profile" : "Your Profile has been updated!"}
          onClose={this.handleClose}
          message={this.state.error ? this.state.error : "Go to Settings, Your Profile update Successfully"}
          isError={!!this.state.error}
          buttonText="Close"
        />
      </Box>
    );
  }
}

const webStyles = {
  inputLabel: { borderRadius: '8px', color: "#FFFFFF", marginBottom: "8px", backgroundColor: "#57534E" },
  profileImg: { height: '50px', borderRadius: '50%', width: '50px' },
  errorStyle: { textAlign: "center" as const, color: "red", marginBottom: 2, fontSize: 12 },
  countryCode: { borderRadius: '8px', color: "#FFFFFF", marginBottom: "8px", backgroundColor: "#57534E", height: '56px', width: '77px', gap: '8px', padding: '16px 8px 16px 8px', border: "none" },
  phoneStyle: { borderRadius: '8px', color: "#FFFFFF", marginBottom: "8px", backgroundColor: "#57534E", height: '56px', width: '100%', opacity: '0px', padding: '16px 270.5px 16px 8.5px' }
};







