import React from "react";
// Customizable Area Start
import { Box, Card, CardContent, CardMedia, Chip, Grid, Tab, Tabs, Typography } from "@mui/material";
import "../../../blocks/dashboard/assets/style.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { estimated, location } from "./assets";
import SharePopup from "./SharePopup.web";
// Customizable Area End
import BakersController, {
  Props,

} from "./BakersController.web";


export default class Bakers extends BakersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <Box sx={{
          color: "white",
          height: "500px",
          overflowY: "auto",
        }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", color: "#C2B30A" }}>
            Baker &nbsp;<span style={{ color: "white" }}>{this.state.showDishes?.data?.attributes?.first_name} </span>
          </Typography>

          <Card sx={{ bgcolor: "#292524", color: "white", mt: 3 }}>
            <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
              <CardMedia
                component="img"
                sx={{ width: 200, height: 200, objectFit: "cover", marginTop: "1rem" }}
                image={this.state.showDishes?.data?.attributes?.profile_pic}
                alt="Baker Emily Davis"
              />
              <CardContent style={{ marginTop: 0, marginLeft: "2rem" }}>
                <div>
                  <Typography variant="body1">
                    Baker Emily Davis, a culinary expert with over a decade of experience,
                    is renowned for her innovative dishes and impeccable presentation. Her
                    skill in blending flavors and her passion for fresh, local ingredients
                    make every meal a true masterpiece.
                  </Typography>
                </div>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
                  {["Cakes", "Cupcakes", "Cookies", "Pies", "Pastries", "Bread", "Dessert Bars", "Muffins", "Tarts", "Specialty Items"].map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      sx={{ bgcolor: "#78716C", color: "white", fontWeight: "bold", borderRadius: "4px" }}
                    />
                  ))}
                </Box>

                <Typography variant="body2" sx={{ mb: 1 }} className="textstyle">
                  State:<span style={{ fontWeight: 700, marginLeft: "12px", color: this.state.showDishes?.data?.attributes?.merchant?.data?.attributes?.is_open ? "#C2B30A" : "red" }}>
                    {this.state.showDishes?.data?.attributes?.merchant?.data?.attributes?.is_open ? "Open" : "Close"}
                  </span>
                </Typography>
                <Typography variant="body2" className="textstyle">Days: {this.state.showDishes?.data?.attributes?.working_days}</Typography>
                <Typography variant="body2" sx={{ mb: 1, mt: 1 }} className="textstyle">
                  Opening Hours: {this.renderOpeningHours()}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }} className="textstyle">
                  Chef's Location:
                </Typography>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", cursor: "pointer", gap: "1rem" }}
                >
                  <span>
                    <img src={location} alt="coming-sson" style={{ width: "18px", height: "18px" }} />

                  </span>
                  {this.state.showDishes?.data?.attributes?.addresses?.data.map((item: any) => {
                    return (
                      <>
                        <span style={{ fontSize: "12px", fontWeight: 700 }}>{item?.attributes?.address}</span>
                      </>
                    )
                  })
                  }

                  <span ><ArrowForwardIosIcon style={{ color: "#C2B30A", marginLeft: "1rem", width: "20px", height: "20px" }} /></span>
                </div>
              </CardContent>
            </Box>
          </Card>
          <div style={{ 
            overflowX: "auto",
             whiteSpace: "nowrap" }}>
            <Tabs
              className="css-jpln7h-MuiTabs-scroller"

              centered
              sx={{
                mt: 3,
                "& .MuiTabs-indicator": {
                  bgcolor: "yellow",
                },
                "& .MuiTabs-scroller": {
                  overflowX: "auto !important",
                },
              }}
              value={this.state.activeTab}
              onChange={this.handleTabChange}
            >
              {Array.isArray(this.state.itemsdishes) &&
                this.state.itemsdishes.map((item: any, index: number) => (
                  <Tab
                    key={index}
                    sx={{
                      color: "white",
                      fontWeight: 400,
                      fontSize: "14px",
                      textTransform: "none",
                    }}
                    label={item.attributes.name}
                    onClick={() => this.functTab(item)}
                  />
                ))}
            </Tabs>
          </div>
          <Box sx={{ mt: 3 }}>

            <>
              <Box sx={{ color: "white", bgcolor: "#292524" }}>
                <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold", color: "white" }}>
                  Baker Specialties
                </Typography>
                <Grid container spacing={2}>
                  {
                    this.state.showDishesfff?.map((item: any) => {
                      return (

                        <>
                          <Grid item xs={12} sm={6} md={4}  >
                            <Card sx={{ bgcolor: "#1C1917", color: "white" }}>
                              {item.attributes?.images?.[0] && (
                                <CardMedia
                                  component="img"
                                  height="150"
                                  image={item.attributes.images[0].url || 'fallback-image-url.jpg'}
                                  sx={{ objectFit: "cover" }}
                                />
                              )}

                              <CardContent>

                                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                  {item.attributes?.name}
                                </Typography>


                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  <Typography variant="body2" sx={{ color: "#D4D4D4", mb: 1, marginTop: "4px" }}>
                                    {item.attributes?.accounts?.attributes?.first_name}
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: "#FFD700", fontWeight: "bold" }}>
                                    {item.attributes?.average_rating} ★
                                  </Typography>
                                </Box>
                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                  {item.attributes?.price} usd
                                </Typography>

                                <Typography variant="body2" sx={{ color: "#A1A1AA" }}>
                                  <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.6rem", marginTop: "9px" }}>
                                    <span> <img src={estimated} alt="" style={{ width: "12px" }} /> </span>
                                    <span style={{ fontSize: "10px" }}>{item.attributes?.estimated_time} min</span>
                                  </div>
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        </>

                      )
                    })
                  }
                </Grid>
              </Box>
            </>


          </Box>
          <SharePopup open={this.state.openPopup} handleClose={this.handleClose} link={this.state.link} />
        </Box>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
