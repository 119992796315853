import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, Typography } from "@mui/material";
import LegalController, { Props } from "./LegalController.web";

export default class UserLegal extends LegalController {
  constructor(props: Props) {
    super(props);
    this.state = {
      tab: "terms", // More descriptive
      TermsAndConditions: "These are the terms and conditions...",
      PrivacyPolicy: "This is the privacy policy...",
      termsCreatedAt: "",
      termsUpdatedAt: "",
      privacyCreatedAt: "",
      privacyUpdatedAt: "",
    };
  }

  handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    this.setState({ tab: newValue });
  };

  render() {
    const { tab, TermsAndConditions, PrivacyPolicy } = this.state;

    return (
      <Box sx={{ width: "100%", height: "100%", backgroundColor: "#292524" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: "28px",
            fontWeight: 700,
          }}
        >
          <span style={{ color: "#C2B30A" }}>Legal</span>
        </Typography>

        <Tabs
          value={tab}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#C2B30A",
            },
          }}
          onChange={this.handleTabChange}
          aria-label="Legal tabs"
          centered
          variant="fullWidth"
        >
          <Tab
            value="terms"
            sx={{
              color: "#ffffff",
              fontWeight: "700",
              textTransform: "none",
              "&.Mui-disabled": {
                pointerEvents: "none",
                color: "#78716C",
                opacity: 1,
              },
              "&.Mui-selected": {
                color: "#ffffff",
              },
            }}
            label="Terms and Conditions"
          />
          <Tab
            value="privacy"
            sx={{
              color: "#ffffff",
              fontWeight: "700",
              textTransform: "none",
              "&.Mui-disabled": {
                pointerEvents: "none",
                color: "#78716C",
                opacity: 1,
              },
              "&.Mui-selected": {
                color: "#ffffff",
              },
            }}
            label="Privacy Policy"
          />
        </Tabs>

        <Box sx={{ marginTop: "20px" }}>
          {tab === "terms" && (
            <Typography variant="body1" sx={{ color: "#ffffff" }}>
              <Typography variant="body1" sx={{ color: "#ffffff", fontSize: "24px", fontWeight: 700 }}>
                <span style={{ color: "rgba(194, 179, 10, 1)" }}>
                  User&nbsp;
                </span>
                Terms and Conditions
              </Typography>
              <Typography variant="body1" sx={{ color: "#ffffff", fontSize: "12px", fontWeight: 700, marginTop: "10px", marginBottom: "15px" }}>
                <span style={{ color: "rgba(168, 162, 158, 1)", fontWeight: 400, fontSize: "10px" }}>
                  Last update&nbsp;
                </span>
                {this.convertDate(this.state.termsUpdatedAt)}
              </Typography>
              {TermsAndConditions}
            </Typography>
          )}
          {tab === "privacy" && (

            <Typography variant="body1" sx={{ color: "#ffffff" }}>
              <Typography variant="body1" sx={{ color: "#ffffff", fontSize: "24px", fontWeight: 700 }}>
                <span style={{ color: "rgba(194, 179, 10, 1)" }}>
                  User&nbsp;
                </span>
                Privacy Policy
              </Typography>
              <Typography variant="body1" sx={{ color: "#ffffff", fontSize: "12px", fontWeight: 700, marginTop: "10px", marginBottom: "15px"}}>
                <span style={{ color: "rgba(168, 162, 158, 1)", fontWeight: 400, fontSize: "10px" }}>
                  Last update&nbsp;
                </span>
                {this.convertDate(this.state.privacyUpdatedAt)}
              </Typography>
              {PrivacyPolicy}
            </Typography>
          )}
        </Box>
      </Box>
    );
  }
}
