import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  location: any;
  history: { push: any };
  activeTab: number
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  selectedValuesdd: string,
  buttonTextbakers: string,
  activeTab: number,
  itemsdishes: any[],
  showDishes: any,
  showDishesfff: any,
  categoryIds: string,
  accountId: string,
  openPopup: boolean,
  link: string,
  openingHours: {}
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class BakersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCategoryItemCallIdthree: any = ""
  apiCategoryItemCallIdtfour: any = ""
  showGetdataCallid: any = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),

    ];

    this.state = {
      selectedValuesdd: 'creattor',
      buttonTextbakers: "Inprogress",
      activeTab: 0,
      itemsdishes: [],
      showDishes: {},
      showDishesfff: [],
      link: "",
      categoryIds: "dynamicCategoryId",
      accountId: "dynamicAccountId",
      openPopup: false,
      openingHours: {}
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    super.componentDidMount();
    this.getCategoriesthree()
    const categoryId = localStorage.getItem("baker_id");
    if (categoryId) {
      this.getCategoriesfour(categoryId)
      this.setState({ accountId: categoryId })
    }else{
      const path = window.location.pathname; 
      const segments = path.split('/');
      const dishId = segments.pop() || '';
      this.getCategoriesfour(dishId)
      this.setState({ accountId: dishId })
    }

    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start
  // Web Event Handling

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.apiCategoryItemCallIdthree) {
      this.handleGetcategories(responseJson);
    } else if (apiRequestCallId === this.apiCategoryItemCallIdtfour) {
      this.handleGetcategShow(responseJson);
    } else if (apiRequestCallId === this.showGetdataCallid) {
      this.handleGetcategcategoryshow(responseJson);
    }


    // Customizable Area End
  }
  // Customizable Area Start

  getCategoriesthree = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCategoryItemCallIdthree = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.categoriesUrlthree}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  showGetdata = (categoryIds: any) => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const params = new URLSearchParams({
      category_id: categoryIds,
      account_id: this.state.accountId
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showGetdataCallid = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showcategoriesbelowtabs}?${params}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  getCategoriesfour = (itemid: any) => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCategoryItemCallIdtfour = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.categoriesUrlfour}/${itemid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleGetcategories = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ itemsdishes: responseJson.data });
    }
  }
  handleGetcategShow = (responseJson: any) => {
    if (responseJson) {
      const openingHours = responseJson.account?.data.attributes.opening_hours;
      this.setState({ showDishes: responseJson.account, openingHours});
    }
  }
  handleGetcategcategoryshow = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ showDishesfff: responseJson.data })
    }
  }
  handleTabChange = (event: any, newValue: any) => {
    this.setState({ activeTab: newValue });
  };

  functTab = (itemID: any) => {
    this.showGetdata(itemID.id);


  };

  handleShare = () => {
    const currentUrl = window.location.href;
    this.setState({ openPopup: true, link: currentUrl })
  }

  handleClose = () => {
    this.setState({ openPopup: false })
  }

  renderOpeningHours = () => {
    const { openingHours } = this.state;
  
    if (!openingHours || Object.keys(openingHours).length === 0) {
      return "No opening hours available.";
    }
  
    const formatTime = (time: string) => {
      const [hour] = time.split(":").map(Number);
      const suffix = hour >= 12 ? "pm" : "am";
      const formattedHour = hour % 12 || 12;
      return `${formattedHour}${suffix}`;
    };
  
    return Object.entries(openingHours)
      .map(([day, times]: [string, any]) => {
        const dayShort = day.slice(0, 3); 
        const open = times.open ? formatTime(times.open) : "N/A";
        const close = times.close ? formatTime(times.close) : "N/A";
        return `${dayShort} ${open} - ${close}`;
      })
      .join(" / ");
  };

  // Customizable Area End
}