import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";

export const configJSON = require("./config");
import { AlertColor } from "@mui/material";


export interface Props {
    navigation: any;
    id: string;
    history: { push: any };
    location: any;
}

interface S {
    close: string;
    files: any;
    specialtyName: string;
    specialtyDescription: string;
    ingredients: string;
    category: string,
    variants: string;
    estimatedTime: string;
    isSpecialtyOfTheDay: string;
    allergens: string;
    ingredientList: any[];
    variantsList: any[];
    selectedCategories: any[];
    extra: string,
    price: string,
    extrasList: any[],
    allergensList: any[];
    totalPrice: string,
    showToast: boolean,
    toastMessages: any[],
    toastSeverity: AlertColor;
    categoryItems: any[];
    open: boolean,
    errorMessages: any[],
    imageURLs: any[],
    newImageURLs: any[],
    confirmDelete: boolean,
    delete: boolean,
    availability: string,
    deleteImages: number[]
}

interface SS {
    id: any;
}

export default class CatalogueCreateController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    getCreateApiCallId: any = "";
    removeImageApiCallId: string = ''
    getCategoryListApiCallId: any = "";
    getEditApiCallId: any = "";
    getImageApiCallId: any = "";
    dishId: any = "";
    deleteListItemApiCallId: any = "";
    getEditAvailabilityApiCallId: any = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        const query = new URLSearchParams(this.props.location.search);
        this.dishId = query.get('dishId');

        if (this.dishId) {
            localStorage.setItem("dishId", this.dishId);
        }

        this.state = {
            close: "",
            files: [],
            specialtyName: '',
            specialtyDescription: '',
            ingredients: '',
            ingredientList: [],
            category: "",
            variants: '',
            estimatedTime: '',
            isSpecialtyOfTheDay: '',
            allergens: '',
            selectedCategories: [],
            variantsList: [],
            extra: '',
            price: '',
            extrasList: [],
            allergensList: [],
            totalPrice: "",
            showToast: false,
            toastMessages: [],
            toastSeverity: 'success' as AlertColor,
            categoryItems: [],
            open: false,
            errorMessages: [],
            imageURLs: [],
            confirmDelete: false,
            delete: false,
            newImageURLs: [],
            availability: "unavailable",
            deleteImages: []
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        super.componentDidMount();
        this.getCategoriesList();
        const savedFormData = localStorage.getItem("formData");
        if (savedFormData) {
            this.setState(JSON.parse(savedFormData));
        }
    }
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
        let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiRequestCallId === this.getCreateApiCallId) {
            this.handleCreateResponse(responseJson);
            let id = responseJson.data.id;
            localStorage.setItem("dishId", id);
            this.imageUpload();
        }
        if (apiRequestCallId === this.getCategoryListApiCallId) {
            const categories = responseJson.data.map((category: any) => ({
                id: category.attributes.id,
                name: category.attributes.name
            }));
            this.setState({ categoryItems: categories });
        }
        if (apiRequestCallId === this.deleteListItemApiCallId) {
            if (responseJson.success) {
                this.setState({ confirmDelete: false, delete: true })
            }
        }
        if (apiRequestCallId === this.getEditApiCallId) {
            this.imageUpload();
            if (responseJson.data.attributes) {
                localStorage.removeItem("formData");
                this.props.history.push("/catalogue")
            }
        }
        if (apiRequestCallId === this.getImageApiCallId) {
            if (responseJson.message) {
                this.handleImageUploadError(responseJson);
            } else {
                this.handleImageUploadSuccess();
            }
        }
    }
    handleImageUploadSuccess() {
        this.handleShowToast("Images uploaded successfully!", "success");
        this.setState({ imageURLs: [] })
        localStorage.removeItem("formData");
    }
    handleImageUploadError = (responseJson: any) => {
        const message = responseJson.message;
        const errorMessage = message || "Image upload failed";
        this.handleShowToast(errorMessage, "error");
        this.setState({
            errorMessages: [errorMessage]
        });
    };
    handleCreateResponse(responseJson: any) {
        if (responseJson?.data?.type !== "error") {
            this.onCreateSuccess(responseJson);
        } else {
            this.onCreateFail(responseJson);
        }
    }
    onCreateSuccess(responseJson: any) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveCreate(responseJson);
        this.handleShowToast("Dish Created successfully!", "success");
        this.setState({ open: true })
    }
    onCreateFail = (responseJson: any) => {
        const errors = responseJson?.data?.attributes?.errors;
        const errorMessages = Object.keys(errors).reduce((acc: any, field: string) => {
            acc[field] = `${field} ${errors[field][0]}`;
            return acc;
        }, {});
        this.setState({
            errorMessages
        });
        const firstErrorField = Object.keys(errors)[0];
        const firstErrorMessage = `${firstErrorField} ${errors[firstErrorField][0]}`;
        this.handleShowToast(firstErrorMessage, "error");
        this.parseApiErrorResponse(responseJson);
        this.sendCreateFailMessage();
    };
    sendCreateFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }
    saveCreate(responseJson: any) {
        if (responseJson) {
            const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
            msg.addData(
                getName(MessageEnum.SessionResponseData),
                JSON.stringify(responseJson)
            );
            this.send(msg);
        }
    }
    handleContinue = () => {
        let token = localStorage.getItem("authToken");
        const header = {
            "token": token
        };
        const {
            specialtyName,
            specialtyDescription,
            ingredientList,
            variantsList,
            extrasList,
            allergensList,
            totalPrice,
            estimatedTime,
            isSpecialtyOfTheDay,
            selectedCategories,
            availability
        } = this.state;
        if (!specialtyName || !specialtyDescription || !totalPrice) {
            this.handleShowToast("Name, Description and Price are Required", "error");
            return;
        }
        const formData = new FormData();
        formData.append("catalogue[name]", specialtyName);
        formData.append("catalogue[description]", specialtyDescription);
        formData.append("catalogue[price]", totalPrice);
        formData.append("catalogue[estimated_time]", estimatedTime);
        formData.append("catalogue[availability]", availability);
        formData.append("catalogue[dish_of_the_day]", isSpecialtyOfTheDay === 'true' ? 'true' : 'false');
        ingredientList.forEach((ingredient: any, index: number) => {
            formData.append(`catalogue[ingredients_attributes][${index}][name]`, ingredient.label);
        });
        variantsList.forEach((variant: any, index: number) => {
            formData.append(`catalogue[variants_attributes][${index}][name]`,
                variant.label);
        });

        extrasList.forEach((extra: string, index: number) => {
            const [name, price] = extra.split(" - $");
            formData.append(`catalogue[extras_attributes][${index}][name]`,
                name);
            formData.append(`catalogue[extras_attributes][${index}][price]`,
                price);
        });

        allergensList.forEach((allergy: any, index: number) => {
            formData.append(`catalogue[allergies_attributes][${index}][name]`,
                allergy.label);
        });

        selectedCategories.forEach((category: any, index: number) => {
            formData.append(`catalogue[category_ids][]`,
                category.id);
        });

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCreateApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createApiEndPoint
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    imageUpload = () => {
        let token = localStorage.getItem("authToken");
        let dishItem = localStorage.getItem("dishId");
        const header = {
            "token": token
        };
        const { imageURLs, newImageURLs } = this.state;
        const endpoint = window.location.href;
        const imagesToSend = endpoint.includes("catalogue-create") ? newImageURLs : imageURLs;
        const formData = new FormData();
        const supportedFormats = [
            'image/jpeg',
            'image/png',
            'image/jpg',
            'image/gif',
            'image/webp',
            'image/svg+xml',
            'image/bmp',
            'image/tiff',
            'image/heic',
            'image/heif'
        ];

        imagesToSend.forEach((image: any, index: number) => {
            const imageUrl = image.url;
            const mimeType = imageUrl.split(';')[0].split(':')[1];
            if (!supportedFormats.includes(mimeType)) {
                this.handleShowToast(`Unsupported image format: ${mimeType}`, "error");
                return;
            }
            const imageBase64 = imageUrl.split(',')[1];
            const byteCharacters = atob(imageBase64);
            const byteArrays: Uint8Array[] = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
                const slice = byteCharacters.slice(offset, offset + 1024);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                byteArrays.push(new Uint8Array(byteNumbers));
            }
            const blob = new Blob(byteArrays, { type: mimeType });
            formData.append(`catalogue[images][${index}]`, blob);
        });
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getImageApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.imageApiEndPoint}/${dishItem}/update_images`
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        let body = formData;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePatch
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    getCategoriesList = () => {
        let token = localStorage.getItem("authToken");
        const header = {
            "Content-Type": configJSON.productApiContentType,
            "token": token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCategoryListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCategoryList}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    removeImage = (id: any) => {
        const token = localStorage.getItem("authToken");
        let dishItem = localStorage.getItem("dishId");
        const header = {
            token
        };
        const formData = new FormData();
        formData.append("image_id", id);
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.removeImageApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_catalogue/catalogues/${dishItem}/delete_image`
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    validateField = (field: keyof S, value: string): boolean => {
        switch (field) {
          case 'isSpecialtyOfTheDay':
            return true;
          case 'totalPrice':
          case 'price':
            return /^\d*\.?\d{0,2}$/.test(value);
          case 'estimatedTime':
            return value === '' || /^\d+$/.test(value);
          case 'allergens':
          case 'extra':
          case 'variants':
          case 'ingredients':
            return /^[a-zA-Z0-9 ]*$/.test(value);
          case 'specialtyDescription':
            return value.length <= 300;
          default:
            return true;
        }
      };
      
      transformFieldValue = (field: keyof S, value: string): string | boolean => {
        if (field === 'isSpecialtyOfTheDay') {
          return value === 'true';
        }
        return value;
      };
      
      handleInputChange = (field: keyof S) => (event: React.ChangeEvent<HTMLInputElement>): void => {
        const rawValue = event.target.value;
        const value = this.transformFieldValue(field, rawValue);
      
        if (typeof value === 'string' && !this.validateField(field, value)) {
          return;
        }
      
        this.setState((prevState) => {
          const newState = { ...prevState, [field]: value };
          localStorage.setItem('formData', JSON.stringify(newState));
          return newState;
        });
      };
      
    
    
    addIngredient = () => {
        const { ingredients, ingredientList } = this.state;
        if (ingredients.trim() !== "") {
            const newIngredient = {
                key: new Date().getTime().toString(),
                label: ingredients.trim()
            };
            const updatedIngredientList = [...ingredientList, newIngredient];
            this.setState({
                ingredientList: updatedIngredientList,
                ingredients: ""
            }, () => {
                const currentState = this.state;
                localStorage.setItem("formData", JSON.stringify(currentState));
            });
        }
    };
    addVariant = () => {
        const { variants, variantsList } = this.state;
        if (variants.trim() !== "") {
            const newVariant = {
                key: new Date().getTime().toString(),
                label: variants.trim()
            };
            const updatedVariantsList = [...variantsList, newVariant];
            this.setState({
                variantsList: updatedVariantsList,
                variants: ""
            }, () => {
                const currentState = this.state;
                localStorage.setItem("formData", JSON.stringify(currentState));
            });
        }
    };
    addAllergens = () => {
        const { allergens, allergensList } = this.state;
        if (allergens.trim() !== "") {
            const newAllergent = {
                key: new Date().getTime().toString(),
                label: allergens.trim()
            };
            const updatedAllergensList = [...allergensList, newAllergent];
            this.setState({
                allergensList: updatedAllergensList,
                allergens: ""
            }, () => {
                const currentState = this.state;
                localStorage.setItem("formData", JSON.stringify(currentState));
            });
        }
    };
    removeIngredient = (ingredientKey: any) => {
        const updatedIngredientList = this.state.ingredientList.filter((ingredient) => ingredient.key !== ingredientKey);
        this.setState({
            ingredientList: updatedIngredientList
        }, () => {
            const currentState = this.state;
            localStorage.setItem("formData", JSON.stringify(currentState));
        });
    };
    removeVariant = (variantKey: any) => {
        const updatedVariantsList = this.state.variantsList.filter((variant) => variant.key !== variantKey);
        this.setState({
            variantsList: updatedVariantsList
        }, () => {
            const currentState = this.state;
            localStorage.setItem("formData", JSON.stringify(currentState));
        });
    };
    removeAllergent = (allergentKey: any) => {
        const updatedAllergensList = this.state.allergensList.filter((allergent) => allergent.key !== allergentKey);
        this.setState({
            allergensList: updatedAllergensList
        }, () => {
            const currentState = this.state;
            localStorage.setItem("formData", JSON.stringify(currentState));
        });
    };
    handleCategoryChange = (event: any) => {
        const selectedCategoryName = event.target.value;
        const selectedCategory = this.state.categoryItems.find(
            (category: any) => category.name === selectedCategoryName
        );
        if (selectedCategory) {
            const newCategory = { id: String(selectedCategory.id), label: selectedCategory.name };
            const isDuplicate = this.state.selectedCategories.some(
                (category: any) => String(category.id) === newCategory.id
            );
            if (isDuplicate) {
                this.handleShowToast(`Category '${newCategory.label}' already exists`, "error");
            } else {
                this.setState(prevState => {
                    const updatedSelectedCategories = [...prevState.selectedCategories, newCategory];
                    return {
                        selectedCategories: updatedSelectedCategories,
                        category: "",
                    };
                }, () => {
                    const currentState = this.state;
                    localStorage.setItem("formData", JSON.stringify(currentState));
                });
            }
        }
    };
    removeCategory = (key: any) => {
        const updatedSelectedCategories = this.state.selectedCategories.filter(
            (category) => category.id !== key
        );
        this.setState({
            selectedCategories: updatedSelectedCategories
        }, () => {
            const currentState = this.state;
            localStorage.setItem("formData", JSON.stringify(currentState));
        });
    };
    handleAddExtra = () => {
        const { extra, price } = this.state;
        if (extra && price) {
            const newExtra = `${extra} - $${price}`;
            this.setState((prevState) => {
                const updatedExtrasList = [...prevState.extrasList, newExtra];
                return {
                    extrasList: updatedExtrasList,
                    extra: '',
                    price: ''
                };
            }, () => {
                const currentState = this.state;
                localStorage.setItem("formData", JSON.stringify(currentState));
            });
        }
    };
    handleDeleteExtra = (index: any) => {
        this.setState((prevState) => {
            const updatedExtrasList = prevState.extrasList.filter((_, i) => i !== index);
            return {
                extrasList: updatedExtrasList,
                price: ''
            };
        }, () => {
            const currentState = this.state;
            localStorage.setItem("formData", JSON.stringify(currentState));
        });
    };
    handleShowToast = (message: string | string[], severity: AlertColor) => {
        const messages = Array.isArray(message) ? message : [message];
        this.setState((prevState: any) => ({
            showToast: true,
            toastMessages: [...(prevState.toastMessages || []), ...messages],
            toastSeverity: severity
        }));
    };
    handleCloseToast = () => {
        this.setState({ showToast: false });
    }
    handleContinue1 = () => {
        const {
            imageURLs,
        } = this.state;
        if (imageURLs.length < 1) {
            this.handleShowToast("Images are Required", "error");
            return;
        }
    
        this.props.history.push("/catalogue/details")
    }
    handleClose = (e: any) => {
        this.setState({ open: false, delete: false });
        this.props.history.push("/catalogue");
        this.setState({
            specialtyName: '',
            specialtyDescription: '',
            ingredients: '',
            ingredientList: [],
            category: "",
            variants: '',
            estimatedTime: '',
            isSpecialtyOfTheDay: 'false',
            allergens: '',
            selectedCategories: [],
            variantsList: [],
            extra: '',
            price: '',
            extrasList: [],
            allergensList: [],
            totalPrice: "",
        });
    }
    handleClose1 = (e: any) => {
        this.setState({ open: false, confirmDelete: false });
        this.props.history.push("/catalogue")
    }
    handleAddImages = (newImages: File[]) => {
        const validImages = newImages.filter(
            (image) => image.type === "image/png" || image.type === "image/jpeg" || image.type === "image/webp"
        );
        if (validImages.length < newImages.length) {
            this.handleShowToast("Only PNG and JPEG images are supported. GIF and other formats are not allowed.", "error");
        }
        if (validImages.length > 0) {
            const imagePromises = validImages.map((image) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        resolve({ url: reader.result, name: image.name });
                    };
                    reader.readAsDataURL(image);
                });
            });

            Promise.all(imagePromises)
                .then((newImageData) => {
                    const savedFormData = localStorage.getItem("formData");
                    let formData = savedFormData ? JSON.parse(savedFormData) : { imageURLs: [] };
                    formData.imageURLs = [...formData.imageURLs, ...newImageData];
                    formData.newImageURLs = [...(formData.newImageURLs || []), ...newImageData];
                    localStorage.setItem("formData", JSON.stringify(formData));

                    this.setState({
                        imageURLs: formData.imageURLs,
                        newImageURLs: formData.newImageURLs
                    });
                })

        }
        setTimeout(() => {
            this.setState({
                showToast: false,
                toastMessages: []
            });
        }, 3000);
    };

    handleRemoveImage = (idToRemove: number, index: number)  => {
        this.setState(prev => ({
            deleteImages: [...prev.deleteImages, idToRemove],
            imageURLs: prev.imageURLs.filter(image => image.id !== idToRemove),
            newImageURLs: prev.newImageURLs.filter((_, indexImg: number) => indexImg !== index)
        }));
    };
    handleDelete = () => {
        let token = localStorage.getItem("authToken");
        let dishId = localStorage.getItem("dishId");
        const header = {
            "Content-Type": configJSON.productApiContentType,
            "token": token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteListItemApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deleteItemsApiEndpoint + "/" + `${dishId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleDeleteClick = () => {
        this.setState({ confirmDelete: true });
    }
    handleEdit = () => {
        this.props.history.push("/catalogue/catalogue-edit")
    }
    handleDishEdit = () => {
        let token = localStorage.getItem("authToken");
        let dishItem = localStorage.getItem("dishId");
        const header = {
            "token": token
        };
        const {
            specialtyName,
            specialtyDescription,
            ingredientList,
            variantsList,
            extrasList,
            allergensList,
            totalPrice,
            estimatedTime,
            isSpecialtyOfTheDay,
            selectedCategories,
            deleteImages
        } = this.state;
        const formData = new FormData();
        formData.append("catalogue[description]", specialtyDescription);
        formData.append("catalogue[name]", specialtyName);
        formData.append("catalogue[estimated_time]", estimatedTime);
        formData.append("catalogue[price]", totalPrice);
        formData.append("catalogue[dish_of_the_day]", isSpecialtyOfTheDay === 'true' ? 'true' : 'false');
        variantsList.forEach((variant: any, index: number) => {
            formData.append(`catalogue[variants_attributes][${index}][name]`, variant.label);
        });
        extrasList.forEach((extra: string, index: number) => {
            const [name, price] = extra.split(" - $");
            formData.append(`catalogue[extras_attributes][${index}][name]`, name);
            formData.append(`catalogue[extras_attributes][${index}][price]`, price);
        });
        allergensList.forEach((allergy: any, index: number) => {
            formData.append(`catalogue[allergies_attributes][${index}][name]`, allergy.label);
        });
        selectedCategories.forEach((category: any, index: number) => {
            formData.append(`catalogue[category_ids][]`, category.id);
        });
        ingredientList.forEach((ingredient: any, index: number) => {
            formData.append(`catalogue[ingredients_attributes][${index}][name]`, ingredient.label);
        });
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getEditApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editApiEndPoint}/${dishItem}`
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        let body = formData;
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePatch
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        deleteImages.forEach(imageId => this.removeImage(imageId));
        return true;
    }
    toggleAvailability = (event: React.MouseEvent<HTMLElement>, newAvailability: string) => {
        let token = localStorage.getItem("authToken");
        let dishId = localStorage.getItem("dishId");
        const header = { "token": token };
        const formData = new FormData();
        formData.append("catalogue[availability]", newAvailability);
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getEditAvailabilityApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.editApiEndPoint}/${dishId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePatch);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ availability: newAvailability });
    }
    getAlertMessage = (message: string[], severity: AlertColor)=>{
        if(message.length > 0){
            this.setState({
                showToast: true,
                toastMessages:[message],
                toastSeverity: severity
            });
        }else{
            this.setState({
                showToast: false,
            });
        }
    }
}
