import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

interface OrderDetail {
  status: string;
  id: number;
  bakerName: string;
  date: string;
  pickup: string;
  images: string[];
  total: number;

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
 
  location: any;
  history: { push: any };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
 
  selectedValue:string,
  showOrderInProgress:boolean,
  open:boolean,
  cancellationTime: string
  orders: any[],
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OrderInprogressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  id = "";
  apiDeleteOrder: any = "";
  getOrderDetailsApiCallId: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      selectedValue: 'creationDateNewest',
      showOrderInProgress:false,
      open:false,
      cancellationTime: "29:59:59",
      orders: [],
     
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    super.componentDidMount();
    this.getOrderDetails();
    // Customizable Area End
  }
  // Customizable Area End
  // Customizable Area Start
  // Web Event Handling

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getOrderDetailsApiCallId) {
      const orders = responseJson.data.map((order: any) => ({
        id: order.id,
        status: order.attributes.status,
        totalFees: order.attributes.total_fees,
        totalItems: order.attributes.total_items,
        totalTax: order.attributes.total_tax,
        items: order.attributes.order_items.data.map((item: any) => ({
          product: {
            id: item.attributes.catalogue.data.attributes.id,
            name: item.attributes.catalogue.data.attributes.name,
            description: item.attributes.catalogue.data.attributes.description,
            price: item.attributes.catalogue.data.attributes.price,
            availability: item.attributes.catalogue.data.attributes.availability,
            orderItemsCount:
              item.attributes.catalogue.data.attributes.order_items_count,
          },
          images: item.attributes.catalogue.data.attributes.images.map(
            (image: any) => image.url
          ),
        })),
      }));
  
      // Update the state
      this.setState({ orders });
    }
   
    // Customizable Area End
  }
  // Customizable Area Start

  getOrderDetails = () => {
    let token = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderInProgress}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  }
  handleButtonClick = () => {
    this.setState({showOrderInProgress:true});
  };
  // Customizable Area End
}