import React from "react";

import UserProfileController, {
    Props,
} from "./UserProfileController.web";

import OutlinedInput from '@mui/material/OutlinedInput';

import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, Typography, InputLabel, FormGroup, FormControlLabel, Checkbox, Input } from "@mui/material";
import CustomDialog from '../../../components/src/CustomDialog';

const InputField = ({ label, name, value, onChange, error, touched, placeholder }: any) => (
    <>
        <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>{label}</InputLabel>
        <OutlinedInput
            fullWidth
            style={{ ...webStyles.inputLabel, position: "unset" }}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={onChange(name)}
            sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                },
            }}
        />
        {error && touched ? <span style={webStyles.errorStyle}>{error}</span> : null}
    </>
);

const PhoneInput = ({ value, onChange, countryCode, handleCountryCodeChange }: any) => (
    <span style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%' }}>
        <Field
            as="select"
            name="countryCode"
            style={webStyles.countryCode}
            value={countryCode}
            onChange={handleCountryCodeChange}
        >
            <option value="91">+91</option>
            <option value="1">+1</option>
            <option value="44">+44</option>
        </Field>
        <OutlinedInput
            fullWidth
            style={webStyles.phoneStyle}
            type="text"
            placeholder="0000000000"
            value={value}
            onChange={onChange("phone")}
        />
    </span>
);


  
export default class UserProfile extends UserProfileController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: 5 }}>
                <div>
                    <label htmlFor="myfileid">
                        <img
                            src={this.state.user.profile_pic || require("../assets/user.png")}
                            style={webStyles.profileImg} width="80" height="80" />
                    </label>
                    <input data-test-id="upload-btn" onChange={(e) => this.updateProfilePic(e.target.files)} type="file" id="myfileid" style={{ display: "none" }} />
                </div>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-evenly", alignItems: "center" }}>
                    <Typography variant='subtitle2' data-test-id="name" style={{ fontSize: '14px', fontWeight: 700, lineHeight: '17.78px' }}>
                        {this.state.user.firstName} {this.state.user.lastName}
                    </Typography>
                    <Typography data-test-id="email" variant='body2'>{this.state.user.email}</Typography>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={this.state.user || { countryCode: '', firstName: "", lastName: "", phone: "", email: "", address: "", state: "", zipcode: "", instaLink: "", fbLink: "" }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string().required("first name is required"),
                        lastName: Yup.string().required("last name is required"),
                        phone: Yup.string().required("Invalid phone number"),
                        email: Yup.string().email().required("Email is required"),
                        address: Yup.string().required("Address is required"),
                        state: Yup.string().required("State is required"),
                        zipcode: Yup.string().required("Zipcode is required"),
                    })}
                    onSubmit={this.saveProfile}
                >
                    {({ handleChange, handleSubmit, errors, touched, values }) => (
                        <>
                            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'center'} width={'65%'}>
                                <InputField label="First Name" name="firstName" value={values.firstName} onChange={handleChange} error={errors.firstName} touched={touched.firstName} placeholder="First Name" />
                                <InputField label="Last Name" name="lastName" value={values.lastName} onChange={handleChange} error={errors.lastName} touched={touched.lastName} placeholder="Last Name" />
                                <PhoneInput value={values.phone} onChange={handleChange} countryCode={values.countryCode} handleCountryCodeChange={this.handleCountryCodeChange} />
                                <InputField label="Email" name="email" value={values.email} onChange={handleChange} error={errors.email} touched={touched.email} placeholder="Email" />
                                <InputField label="Address" name="address" value={values.address} onChange={handleChange} error={errors.address} touched={touched.address} placeholder="Address" />
                                <InputField label="State" name="state" value={values.state} onChange={handleChange} error={errors.state} touched={touched.state} placeholder="State" />
                                <InputField label="Zip Code" name="zipcode" value={values.zipcode} onChange={handleChange} error={errors.zipcode} touched={touched.zipcode} placeholder="Zipcode" />
                                <InputField label="Instagram Link (optional)" name="instaLink" value={values.instaLink} onChange={handleChange} error={errors.instaLink} touched={touched.instaLink} placeholder="Instagram Link" />
                                <InputField label="Facebook Link (optional)" name="fbLink" value={values.fbLink} onChange={handleChange} error={errors.fbLink} touched={touched.fbLink} placeholder="Facebook link" />
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Working days</InputLabel>
                                <Box data-test-id='workingDaysBox' sx={{ width: '100%', borderRadius: '8px', p: "16px 8px", background: '#57534E', boxSizing: 'border-box' }}>
                                    <FormGroup sx={{ flexDirection: 'row' }}>
                                        {this.state.daysOfWeek.map(day => (
                                            <FormControlLabel
                                                key={day.val}
                                                data-test-id={`checkbox${day.val}`}
                                                control={<Checkbox sx={{ "&.Mui-checked": { color: '#01AA7F' } }} />}
                                                label={day.val}
                                                checked={day.isChecked}
                                                onChange={() => this.handlCheckDay(day.val)}
                                            />
                                        ))}
                                    </FormGroup>
                                </Box>
                                {this.state.daysOfWeek.filter(day => day.isChecked).length > 0 && (
                                    <>
                                        <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px", marginTop: '10px' }}>Opening hours</InputLabel>
                                        <Box>
                                            {this.state.daysOfWeek.filter(day => day.isChecked).map(day => (
                                                <Box key={day.val} sx={{ display: 'flex', gap: '16px', alignItems: 'center', marginBottom: '8px' }}>
                                                    <Typography sx={{ color: "#ffffff", fontSize: "16px", width: '114px' }}>{day.label}</Typography>
                                                    <Input
                                                        disableUnderline
                                                        data-test-id="checkedDays"
                                                        style={webStyles.hoursInput}
                                                        inputProps={{
                                                            sx: { textAlign: 'center' }
                                                        }}
                                                        value={day.open}
                                                        onChange={this.handleInputChange.bind(this, day.val, 'open')}
                                                    />
                                                    <Typography sx={{ color: "#ffffff", fontSize: "16px" }}>to</Typography>
                                                    <Input
                                                        disableUnderline
                                                        data-test-id={`close${day.val}`}
                                                        style={webStyles.hoursInput}
                                                        inputProps={{
                                                            sx: { textAlign: 'center' }
                                                        }}
                                                        value={day.close}
                                                        onChange={this.handleInputChange.bind(this, day.val, 'close')}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    </>
                                )}



                            </Box>

                            <Button
                                data-test-id={"submitBtn"}
                                sx={{
                                    padding: '15px',
                                    borderRadius: '8px',
                                    color: '#ffffff',
                                    backgroundColor: '#C2B30A',
                                    border: "1px solid #C2B30A",
                                    width: '343px',
                                    height: '52px',
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    marginTop: "22px",
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: '#a59a00',
                                        border: "1px solid #a59a00"
                                    },
                                }}
                                color="secondary"
                                variant="outlined"
                                onClick={() => handleSubmit()}
                            >
                                Save Changes
                            </Button>
                        </>
                    )}
                </Formik>

                <CustomDialog
                    testID="profileUpdateDialog"
                    isOpen={this.state.open}
                    onClose={this.handleClose}
                    title={this.state.error ? "Error Updating Profile" : "Your Profile has been updated!"}
                    message={
                        this.state.error
                            ? this.state.error
                            : "Go to Settings, Your Profile update Successfully"
                    }
                    buttonText="Close"
                    isError={this.state.error ? true : false}
                />
            </Box>
        )
    }
}

const webStyles = {
    inputLabel: {
        color: "#FFFFFF",
        borderRadius: '8px',
        backgroundColor: "#57534E",
        marginBottom: "8px",
    },
    profileImg: {
        width: '50px',
        height: '50px',
        borderRadius: '50%',
    },
    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12,
        marginBottom: 2
    },
    mainWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        backgroundColor: "#ffff",
        width: "50%",
        fontFamily: "Roboto, sans-serif"
    },
    titleText: {
        fontSize: 30,
        paddingVertical: 10,
        fontWeight: "600",


    },
    bodyText: {
        fontSize: 20,
        paddingVertical: 15,
        fontWeight: "400",
    },
    inputStyle: {
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        backgroundColor: "rgb(98, 0, 238)",
    },
    countryCode: {
        color: "#FFFFFF",
        borderRadius: '8px',
        backgroundColor: "#57534E",
        marginBottom: "8px",
        width: '77px',
        height: '56px',
        padding: '16px 8px 16px 8px',
        gap: '8px',
        border: "none"
    },
    phoneStyle: {
        color: "#FFFFFF",
        borderRadius: '8px',
        backgroundColor: "#57534E",
        marginBottom: "8px",
        width: '100%',
        height: '56px',
        padding: '16px 270.5px 16px 8.5px',
        opacity: '0px',
    },
    hoursInput: {
        background: '#57534E',
        width: '80px',
        height: '50px',
        color: 'white',
        borderRadius: '8px',
        textAlign: 'center' as const
    }
};