import React from "react";
import { Box, Button, Typography } from "@mui/material";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import AcceptOrDeliverModalController, { Props } from "./AcceptOrDeliverModalController.web";
import moment from "moment"

export default class AcceptOrDeliverModal extends AcceptOrDeliverModalController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const orderData = this.props.orderData.length > 0 ? this.props.orderData[0].attributes : null;
        return (
            <Dialog
                sx={{
                    padding: '40px 16px',
                    borderRadius: '8px',
                    '& .MuiDialog-paper': {
                        backgroundColor: '#000000',
                    },
                }}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container>
                        <Grid item xs={12}  >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                <Typography variant="h2" sx={{
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    textAlign: "center"
                                }}>
                                    <span style={{ color: "#C2B30A" }}>
                                        Order&nbsp;
                                    </span>
                                    <span style={{ color: "#ffffff" }}>
                                        #{this.props.orderId}
                                    </span>
                                </Typography>
                                <img onClick={this.props.handleClose} src={require("../assets/close.svg")} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', padding: "16px 8px 5px 0px" }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "12px", color: "#D6D3D1", fontWeight: 400 }} variant="subtitle1" color="text.secondary" component="div">
                                        Client: &nbsp;
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", color: "#ffffff", fontWeight: 400 }} variant="subtitle1" component="div">
                                        {this.state.customer?.first_name} {this.state.customer?.last_name}
                                    </Typography>
                                </Box>
                                <Typography sx={{ fontSize: "12px", color: "#ffffff", fontWeight: 400 }} variant="subtitle1" component="div">
                                    {orderData?.order_created_at && moment(orderData.order_created_at).format('hh:mm:ss')}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', padding: "5px 8px 5px 0px" }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "12px", color: "#D6D3D1", fontWeight: 400 }} variant="subtitle1" color="text.secondary" component="div">
                                        Time to pick up: &nbsp;
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", color: "#ffffff", fontWeight: 400 }} variant="subtitle1" component="div">
                                        {this.state.pickup}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderColor: "#57534E" }} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container>
                            {this.props.orderData.map(order => (
                                <React.Fragment key={order.id}>
                                    <Grid item xs={12}>
                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                            {order.attributes.quantity}x
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                            {order.attributes.catalogue.data.attributes.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                            ${order.attributes.catalogue.data.attributes.price.toFixed(2)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List sx={{ listStyleType: 'disc', paddingLeft: '25px', paddingTop: "0px" }}>
                                            {order.attributes.extras.map(extra => (
                                                <ListItem key={extra.id} sx={{ display: 'list-item', color: '#ffffff', padding: '3px 2px', fontSize: "14px", fontWeight: "400" }}>
                                                    <Box justifyContent='space-between' display='flex' >
                                                        {extra.name}   
                                                        <Typography color={'#ffffff'} sx={{ fontWeight: "700", fontSize: "16px", paddingRight: '41px' }} gutterBottom variant="subtitle1" component="div">
                                                            ${extra.price}   
                                                        </Typography>
                                                    </Box>
                                                </ListItem>
                                            ))}
                                        </List>
                                        <Divider sx={{ marginBottom: "15px", borderColor: "#57534E",  }} />
                                    </Grid>
                                    <Grid item xs={9}></Grid>
                                    <Grid item xs={3}>
                                        <Box
                                            alignItems="center"
                                            display="flex"
                                            sx={{ width: '100%' }}
                                            justifyContent="flex-end"
                                        >
                                            <Typography sx={{  color: "#A8A29E", fontSize: "16px",fontWeight: 700 }} variant="subtitle1" color="text.secondary" component="div">
                                                Total: &nbsp;
                                            </Typography>
                                            <Typography color={'#ffffff'} sx={{ margin: '0', fontSize: "18px", fontWeight: 700, }} gutterBottom variant="subtitle1" component="div">
                                                {this.state.price}  
                                            </Typography>
                                        </Box>

                                    </Grid>
                                </React.Fragment>
                            ))}
                            {this.state.data.length != 0 &&
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                            {this.state.pickupTime.orderQuantity}x
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                            {this.state.pickupTime.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                            ${this.state.pickupTime.item_price}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List sx={{ listStyleType: 'disc', paddingLeft: '25px', paddingTop: "0px" }}>
                                            {this.state.pickupTime.extras?.map((extra: any, index: any) => (
                                                <ListItem key={extra.name || index} sx={{ display: 'list-item', color: '#ffffff', padding: '3px 2px', fontSize: "14px", fontWeight: "400" }}>
                                                    <Box display='flex' justifyContent='space-between'>
                                                        {extra.name}
                                                        <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700", paddingRight: '41px' }} gutterBottom variant="subtitle1" component="div">
                                                            ${extra.price}
                                                        </Typography>
                                                    </Box>
                                                </ListItem>
                                            ))}

                                        </List>
                                        <Divider sx={{ borderColor: "#57534E", marginBottom: "15px" }} />
                                    </Grid>
                                    <Grid item xs={9}>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="flex-end"
                                            sx={{ width: '100%' }}
                                        >
                                            <Typography sx={{ fontSize: "16px", color: "#A8A29E", fontWeight: 700 }} variant="subtitle1" color="text.secondary" component="div">
                                                Total: &nbsp;
                                            </Typography>
                                            <Typography color={'#ffffff'} sx={{ fontSize: "18px", fontWeight: 700, margin: '0' }} gutterBottom variant="subtitle1" component="div">
                                                {this.state.price}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                {this.props.type != 'declined' &&
                    <DialogActions
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: '16px',
                            gap: "10px"
                        }}
                    >
                        <Button
                            onClick={
                                this.props.type == 'accept' ? this.props.openDeclineModal : this.props.handleClose
                            }
                            sx={{
                                width: '343px',
                                height: '55px',
                                padding: '16px 24px',
                                borderRadius: '8px',
                                border: "1px solid #ffffff",
                                color: '#ffffff',
                                backgroundColor: 'transparent',
                                fontSize: '16px',
                                fontWeight: 700,
                                textTransform: 'none'
                            }}
                            data-testid="opn-store3"

                        >{this.props.type == 'accept' ? "Decline order" : "Cancel Order"}</Button>
                        <Button
                            onClick={this.props.type === "accept" || this.props.type === "payment_completed" ? this.handleAccept : this.handleWaitingClient}
                            data-test-id="handleAccept"
                            sx={{
                                width: '343px',
                                padding: '16px 24px',
                                borderRadius: '8px',
                                color: '#ffffff',
                                backgroundColor: '#C2B30A',
                                '&:hover': {
                                    backgroundColor: '#a59a00',
                                },
                                fontSize: '16px',
                                fontWeight: 700,
                                textTransform: 'none'
                            }}
                        >
                            {this.enumcard[this.props.type]}
                        </Button>
                    </DialogActions>
                }
                <div style={{ display: "flex", justifyContent: "center", padding: '16px', }}>
                    {this.props.type == 'declined' && (
                        <Button
                            data-test-id="handleAccept"
                            sx={{
                                width: '343px',
                                padding: '16px 24px',
                                borderRadius: '8px',
                                color: '#ffffff',
                                backgroundColor: 'rgba(199, 22, 29, 1)',
                                '&:hover': {
                                    backgroundColor: 'rgba(199, 22, 29, 1)',
                                },
                                fontSize: '16px',
                                fontWeight: 700,
                                textTransform: 'none'
                            }}
                        >
                            Declined
                        </Button>
                    )}
                </div>
            </Dialog>
        );
    }
}