Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.markAsReadMethod1 = "PATCH";
exports.deleteMethod = "DELETE";
exports.getNotification = "bx_block_notifications/notifications";
exports.markAllRead = "bx_block_notifications/read_all";
exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.getOrderNotication = "/bx_block_notifications/notifications"
// Customizable Area End