import React from "react";
// Customizable Area Start
import {
  Avatar, Box, Button, Card,
  CardMedia,
  CardContent, Chip, FormControl, FormControlLabel, Grid, IconButton, RadioGroup, Tab, Tabs, Typography, styled, Radio, TextField,
  Divider, Snackbar, Alert } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import { star, time, filterIcon, share } from "./assets";

const specialties = [
  'Cakes', 'Cupcakes', 'Cookies', 'Pies', 'Pastries',
  'Bread', 'Dessert bars', 'Muffins', 'Tarts', 'Specialty items'
];
const CustomRadionew = styled(Radio)(({ theme }) => ({
  color: '#ffeb3b',
  '&.Mui-checked': {
    color: '#ffeb3b',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
  },
}));

// Customizable Area End
import DishesController, {
  Props,

} from "./DishesController.web";
import { downArrow } from "./assets";
import SharePopup from "./SharePopup.web";

export default class Dishes extends DishesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box style={{ width: "100%" }}>
        <Box sx={{ width: '100%', color: 'white', }}>
          <Tabs
            value={this.state.activeTab}
            onChange={this.handleTabChange}
            centered
            textColor="inherit"
            TabIndicatorProps={{
              style: { backgroundColor: 'yellow' },
            }}
            sx={{ display: 'flex', justifyContent: 'space-around', alignItems: "center" }}
            data-test-id="tabs"
          >
            <Tab label="Dishes" sx={{ mx: 20 }} />
            <Tab label="Bakers" sx={{ mx: 20 }} />
          </Tabs>
          <Snackbar
                    open={this.state.showToast}

                    autoHideDuration={6000}

                    onClose={this.handleCloseToast}

                    data-test-id="btnClose"
                >

                    <Alert onClose={this.handleCloseToast} severity={this.state.toastSeverity}>
                        {this.state.toastMessage}

                    </Alert>

                </Snackbar>
          <Box style={{ marginTop: "3rem" }} data-test-id="main-container">
            {this.state.activeTab == 0 && (
              <Box data-test-id="firstTab" sx={{ width: '100%', height: "100%", overflowY: 'auto', marginTop: "10px" }}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', width: "343px", height: "32px" }}>
                    <TextField
                      data-test-id="searchbar"
                      variant="outlined"
                      placeholder="Search"
                      value={this.state.searchQuery}
                      InputProps={{
                        style: {
                          color: 'white',
                          paddingLeft: "12px",
                          height: "34px"
                        },
                        endAdornment: (
                          <IconButton>
                            <SearchIcon style={{ color: 'white' }} />
                          </IconButton>
                        )
                      }}
                      InputLabelProps={{
                        style: { fontSize: '12px' }
                      }}
                      style={{
                        borderRadius: '4px',
                        backgroundColor: '#57534E',
                        marginRight: '10px',
                        flex: 1,
                      }}
                    />
                  </div>
                  <div>
                    <div style={{ position: 'relative' }}>
                      <IconButton
                        style={{
                          height: '32px',
                          color: 'white',
                          backgroundColor: '#57534E',
                          width: '112px',
                          marginRight: '10px',
                          borderRadius: '4px',
                        }}
                      >
                        <div
                          style={{
                            gap: '6px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <SortIcon style={{ width: '20px', height: '20px' }} />
                          <Typography style={{ fontSize: '12px', color: 'white', fontWeight: 400 }}>
                            Sort by
                          </Typography>
                          <img src={downArrow} alt="No-data-found" />
                        </div>
                      </IconButton>
                    </div>
                  </div>
                  <div>
                    <IconButton style={{ backgroundColor: '#57534E', color: 'white', borderRadius: '4px', height: "32px" }}>
                      <img src={filterIcon} alt="filter icon" style={{ width: "12px", height: "12px" }} />
                    </IconButton>
                  </div>

                </div>
                <Box sx={{
                  display: "flex", maxWidth: "100%", overflowX: "auto", p: 2, gap: 2,
                  '&::-webkit-scrollbar': {
                    height: '8px',
                  },
                }}>
                  {this.state.categories.map((category) => (
                    <Box
                      key={category.id}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: 100,
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={category.image}
                        alt={category.name}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: 8,
                          marginBottom: "10px",
                        }}
                      />
                      <Typography style={{ width: "100%", fontSize: "12px" }} variant="body2" align="center">
                        {category.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: "700" }}>
                    Most Popular Dishes
                  </Typography>
                  <Grid container spacing={2}>
                    {this.state.mostPopularItems.map((item) => (
                      <Grid onClick={()=>this.getMostPopularDishDetails(item.id)} item xs={12} sm={6} md={3} key={item.id} data-test-id="dishes">
                        <Card style={{ backgroundColor: "transparent", color: "white", boxShadow: "none" }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={item.dishImg && item.dishImg[0]}
                            alt={item.title}
                            style={{ borderRadius: "10px" }}
                          />
                          <CardContent>
                            <Typography variant="subtitle1" style={{ fontWeight: "700", fontSize: "14px" }}>
                              {item.title}
                            </Typography>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography variant="body2">
                                <span style={{ fontWeight: "bold", fontSize: "10px" }}>
                                  Baker &nbsp;
                                  <span style={{ color: "rgba(231, 229, 228, 1)" }}>
                                    {item.bakerFirstName} {item.bakerLastName}
                                  </span>
                                </span>
                              </Typography>
                              <Typography variant="body2">
                                <span style={{ color: "rgba(245, 158, 11, 1)" }}>{item.rating}</span>
                                <img
                                  style={{ width: "12px", marginLeft: "5px" }}
                                  src={star}
                                  alt="star"
                                />
                              </Typography>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                variant="body2"
                                fontWeight="bold"
                                style={{ fontWeight: 700, fontSize: "14px" }}
                              >
                                {item.price} usd
                              </Typography>
                            </div>
                            <Box
                              sx={{
                                gap: 1,
                                fontSize: "10px",
                                display: "flex",
                                alignItems: "center",
                                color: "rgba(168, 162, 158, 1)",
                              }}
                            >
                              <img src={time} alt="time" />
                              <Typography variant="body2" style={{ fontSize: "10px" }}>
                                {item.time} min
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>

                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: "700" }}>
                    Popular Specialties
                  </Typography>
                  <Grid container spacing={2} data-test-id="popularSpeciality">
                    {this.state.popularItems.map((item) => (
                      <Grid onClick={()=>this.getMostPopularDishDetails(item.id)} item xs={12} sm={6} md={4} key={item.id}>
                        <Card style={{ backgroundColor: "transparent", color: "white", boxShadow: "none" }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={item.dishImg && item.dishImg[0]}
                            alt={`Dish: ${item.title}`}
                            style={{ borderRadius: "10px" }}
                          />
                          <CardContent>
                            <Typography variant="subtitle1" style={{ fontWeight: "700", fontSize: "14px" }}>
                              {item.title}
                            </Typography>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "10px" }}>
                                {item.bakerFirstName}{item.bakerLasttName}
                              </Typography>
                              <Typography variant="body2">
                                <span style={{ color: "rgba(245, 158, 11, 1)" }}>
                                  {item.rating ?? "No Rating"}
                                </span>
                                <img style={{ width: "12px", marginLeft: "5px" }} src={star} alt="star icon" />
                              </Typography>
                            </div>
                            <Typography variant="body2" style={{ fontWeight: "700", fontSize: "14px" }}>
                              {item.price} usd
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, color: "rgba(168, 162, 158, 1)" }}>
                              <img src={time} alt="Estimated time icon" />
                              <Typography variant="body2" style={{ fontSize: "10px" }}>
                                {item.time} min
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>

                </Box>

              </Box>
            )}
            {this.state.activeTab === 1 && (
              <Box data-test-id="tab2">
                <div >
                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', width: "343px", height: "32px" }}>
                      <TextField
                        data-test-id="searchbar"
                        variant="outlined"
                        placeholder="Search"
                        InputProps={{
                          style: { color: 'white', paddingLeft: "12px", height: "34px" },
                          endAdornment: (
                            <IconButton>
                              <SearchIcon style={{ color: 'white' }} />
                            </IconButton>
                          )

                        }}
                        InputLabelProps={{
                          style: { fontSize: '12px' }
                        }}

                        style={{
                          backgroundColor: '#57534E',
                          borderRadius: '4px',
                          flex: 1,
                          marginRight: '10px',
                        }}
                      />

                    </div>
                    <div>
                      <div style={{ position: 'relative' }}>
                        <IconButton
                          style={{
                            color: 'white',
                            height: '32px',
                            width: '120px',
                            backgroundColor: '#57534E',
                            borderRadius: '4px',
                            marginRight: '10px',
                            maxWidth: "120px"
                          }}
                          onClick={this.toggleMenubakers}
                        >
                          <div
                            style={{
                              gap: '6px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <SortIcon style={{ width: '20px', height: '20px' }} />
                            <Typography style={{ fontSize: '12px', color: 'white', fontWeight: 400, }}>
                              Sort by
                            </Typography>
                            <img src={downArrow} alt="No-data-found" />
                          </div>
                        </IconButton>
                        {this.state.openbakers && (
                          <div style={{
                            position: 'absolute',
                            top: '40px',
                            left: '0',
                            color: 'black',
                            marginTop: '10px',
                            zIndex: 1
                          }}>
                            <div style={{ width: "375px", height: "409px", background: "black", borderRadius: "8px" }}>
                              <div style={{ padding: "1rem" }}>
                                <Typography variant="h6" sx={{ paddingTop: "13px", fontWeight: 800, mb: 2, fontSize: "24px", lineHeight: "24px", color: "#C2B30A" }}>
                                  Sort by
                                </Typography>
                                <FormControl component="fieldset">
                                  <RadioGroup data-test-id="CustomRadionew" value={this.state.selectedValuebakers} onChange={this.handleChangebakerss}>
                                    <FormControlLabel
                                      value="nameAsc"
                                      control={<CustomRadionew />}
                                      label="Name (ascending)"
                                      sx={{ color: '#fff' }}
                                    />
                                    <FormControlLabel
                                      value="nameFsc"
                                      control={<CustomRadionew />}
                                      label="Price (lowest first)"
                                      sx={{ color: '#fff' }}
                                    />
                                    <FormControlLabel
                                      value="nameDsc"
                                      control={<CustomRadionew />}
                                      label="Name (descending)"
                                      sx={{ color: '#fff' }}
                                    />

                                    <FormControlLabel
                                      value="oldDatesc"
                                      control={<CustomRadionew />}
                                      label="Creation Date (oldest first)"
                                      sx={{ color: '#fff' }}
                                    />
                                    <FormControlLabel
                                      value="newDatesc"
                                      control={<CustomRadionew />}
                                      label="Creation Date (newest first)"
                                      sx={{ color: '#fff' }}
                                    />



                                  </RadioGroup>
                                </FormControl>
                                <Button
                                  variant="contained"
                                  sx={{
                                    bgcolor: '#ffeb3b',
                                    color: '#000',
                                    mt: 2,
                                    borderRadius: "8px",
                                    padding: "1rem",
                                    width: '100%',

                                    '&:hover': {
                                      bgcolor: '#fdd835',
                                    },
                                  }}
                                  onClick={() => this.setState({ openbakers: false })}
                                >
                                  Apply
                                </Button>
                              </div>

                            </div>

                          </div>


                        )}
                      </div>
                    </div>
                    <div>
                      <IconButton style={{ backgroundColor: '#57534E', color: 'white', borderRadius: '4px', height: "32px" }}>
                      <img src={filterIcon} alt="filter icon" style={{ width: "12px", height: "12px" }} />
                      </IconButton>
                    </div>

                  </div>
                  <div>
                    {Array.isArray(this.state.items) &&
                      this.state.items.map((item) => (
                        <>
                        <Box
                          data-test-id="itemsBox"
                          key={item.id}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderRadius: "10px",
                            color: "white",
                            width: "100%",
                            gap: 2,
                            marginTop: "1rem",
                          }}
                        >
                          <img
                            src={item.profilepic}
                            alt={item.profilepic}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: 8,
                              marginBottom: "10px",
                            }}
                          />

                          <Box sx={{ flex: 1 }}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                display: "inline-block",
                              }}
                            >
                              Baker
                            </Typography>
                            <Typography
                              variant="h5"
                              component="span"
                              sx={{
                                fontWeight: "bold",
                                marginLeft: "10px",
                              }}
                            >
                              {item?.bakerfirstname}
                            </Typography>

                            <Typography
                              variant="body1"
                              sx={{
                                marginTop: "10px",
                                fontSize: "0.9rem",
                              }}
                            >
                              Chef Johnathan Smith, with 15 years of experience, is a culinary
                              expert renowned for his mastery of diverse cuisines, exquisite
                              plating, and perfect flavor balance. His passion and precision
                              transform every dish into a delightful masterpiece.
                            </Typography>

                            <Grid container spacing={1} sx={{ marginTop: "10px" }}>
                              {specialties.map((specialty, index) => (
                                <Grid item key={index}>
                                  <Chip
                                    label={specialty}
                                    sx={{
                                      backgroundColor: "#78716C",
                                      color: "white",
                                      fontWeight: "bold",
                                      borderRadius: "4px",
                                    }}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </Box>

                          <div
                            style={{
                              height: "10rem",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                          >
                            <Button
                              variant="text"
                              sx={{
                                color: "white",
                                textTransform: "none",
                                fontWeight: "bold",
                              }}
                              endIcon={<ChevronRightIcon fontSize="small"/>}
                              onClick={() => this.goTospecialities(item.id)}
                              data-test-id="specialties"
                            >
                              See specialties
                            </Button>

                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                              <Typography
                                data-test-id="share2"
                                onClick={() => this.handleShare(item.id)}
                                variant="body1"
                                sx={{
                                  fontSize: "10px",
                                  color: "#C2B30A",
                                  mr: 2,
                                  ml: 2,
                                }}
                              >
                                Share Baker
                                <img src={share} alt="share" />
                              </Typography>
                            </div>
                          </div>
                        </Box>
                        <Divider sx={{width:'100%', background: '#57534E', margin: '8px 0'}} />
                        </>
                      ))}
                  </div>
                </div>
              </Box>
            )}
          </Box>
        </Box>
        <SharePopup open={this.state.openPopup} handleClose={this.handleClose} link={this.state.link} />
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}


// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
  nextstyle: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'center',
    color: "white"
  },
  tableScrollContainer: {
    maxHeight: "100%",

  },
  headerCell4: {
    color: "#A8A29E",
    fontSize: '12px',
    fontWeight: 700,

    textAlign: 'center',
    borderBottom: 'none'

  },
  headerCell: {
    color: "#A8A29E",
    borderBottom: 'none',
    fontSize: '12px',
    fontWeight: 700,
  },
  chip: {
    background: "#55D9B4",
    color: "#F5F5F5", fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.4px',


  },
  main: {
    height: "100%",
    width: "100%",
    overflowY: "scroll",
  },
  cell3: {
    textAlign: "center", borderBottom: 'none', color: "white", fontSize: '12px',
    fontWeight: 700,

  },
  cell: {
    borderBottom: 'none', color: "white", fontSize: '12px',
    fontWeight: 400,

  },

  btns: {
    borderRadius: "25px",
    background: "#57534E",

    color: "#ffffff",
    width: "77px",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 10px"
  },
  button: {
    background: "#55D9B4",
    color: "#ffffff",
    padding: "15px",
    fontWeight: "700",
    marginBottom: "20px",
    borderRadius: "10px",
    marginTop: "30px"
  },
};
// Customizable Area End
